import React from 'react'
import Icon from './Icon';
import './Styles/ContactList.css'

export default function ContactList({ title, description, contactType }) {

	const mode = localStorage.getItem('mode')

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Submitted Successfully")
	}
	return (
		<form className='contactList' onSubmit={(e) => handleSubmit}>
			<div className="contactListTop">
				<Icon type="contact" />
				<div className="contactListInfo">
					<div className='contactListTitle'>{title}</div>
					<div className='contactListDescription'>{description}</div>
				</div>
			</div>
			<div className="ContactListActions">
				<div className="ContactListInputsContainer">
					<input required className='contactListInput' type="text" placeholder='الاسم' />
					<input required className='contactListInput' type={contactType} placeholder={contactType === "email" ? 'البريد الإلكتروني' : "رقم الهاتف"} />
				</div>
				<button className='contactListButton' type='submit'>
					<div>إرسال</div>
					<div className='contactListIcon' style={{ filter: mode === 'light' ? 'brightness(100%)' : 'invert(1) brightness(100%)' }} />
				</button>
			</div>
		</form>
	)
}
