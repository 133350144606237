import React, { useEffect, useState } from 'react'

import LinkFolder from '../HomeComponents/LinkFolder';
import TextBlock from '../HomeComponents/TextBlock';
import './Styles/Home.css'
import '../index.css'

import Icon from '../HomeComponents/Icon';
import CardBlock from '../HomeComponents/CardBlock';
import ContactList from '../HomeComponents/ContactList';
import Footer from '../AppComponents/Footer';
import BlogPost from '../AppComponents/BlogPost';
import SaveContact from '../HomeComponents/SaveContact';
import Blog from './Blog';

import { data } from "../Database.js"

export default function Home() {
	const [active, setActive] = useState('Home');
	const [showSaveContact, setShowSaveContact] = useState(false);
	const [showBlog, setShowBlog] = useState(null);
	const [websiteData, setWebsiteData] = useState(null);

	useEffect(() => {
		if (data) {
			setWebsiteData(data.website);
			localStorage.setItem('mode', data.website.theme.mode);
		}
	}, []);

	useEffect(() => {
		if (websiteData) {
			if (websiteData.theme?.direction === "rtl") {
				document.body.style.direction = 'rtl';
			}

			const setThemeColors = () => {
				document.documentElement.style.setProperty('--primary-color', websiteData.theme.primaryColor);
				document.documentElement.style.setProperty('--secondary-color', websiteData.theme.secondaryColor);
				document.documentElement.style.setProperty('--primary-background', websiteData.theme.primaryBackground);
				document.documentElement.style.setProperty('--secondary-background', websiteData.theme.secondaryBackground);
			};

			setThemeColors();
		}
	}, [websiteData]);

	useEffect(() => {
		if (showBlog) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [showBlog]);

	if (!websiteData) return <div>loading ...</div>;

	const convertNewlinesToBreaks = (text) => {
		return text.split('\n').map((item, index) => (
			<React.Fragment key={index}>
				{item}
				<br />
			</React.Fragment>
		));
	};
	return (
		<div className='homeContainer'>
			{showBlog && <Blog {...showBlog} body={convertNewlinesToBreaks(showBlog.body)} CloseBlog={() => setShowBlog(null)} />}
			<div className='profileDetailsContainer'>
				<div className="profileDetails">
					<div className="profileThumbnail"
						style={{
							backgroundImage: `url(${websiteData.info.thumbnail})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat"
						}}
					/>
					<div className={true ? "profilePhoto withThumbnail" : "profilePhoto noThumbnail"}
						onClick={() => setShowSaveContact(false)}
						style={{
							backgroundImage: `url(${websiteData.info.profilePic})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat"
						}} />
					<div className="profileName">
						<div className="profileNameText">{websiteData.info.name}</div>
						<div className="blueVerification"></div>
					</div>
					<div className="profileBio">
						{convertNewlinesToBreaks(websiteData.info.bio)}
					</div>

					<div className="socialMediaIcons">
						{
							Object.entries(websiteData.social).map(([icon, link], key) => <Icon key={key} type={icon} link={link} />)
						}
					</div>

				</div>
				<Footer ClassName="desktopFooter" />
			</div>
			<div className='homeContent'>
				{
					<div className="homeMenuContainer">
						<div className='homeMenu'>
							<div className={active === "Home" ? 'selected' : null}
								onClick={() => setActive("Home")}>
								{
									// Home
									"الرئيسية"
								}
							</div>
							<div className={active === "Posts" ? 'selected' : null}
								onClick={() => setActive("Posts")}>
								{
									// Posts
									"المدوّنة"
								}
							</div>
						</div>
					</div>
				}

				{
					active === "Home" ?
						<div>
							{
								Object.entries(websiteData.content).map(([key, block]) =>
									block.type === "linkFolder" ?
										<LinkFolder key={key} {...block} direction={websiteData.theme.direction} />
										: block.type === "textBlock" ?
											<TextBlock key={key} {...block} />
											: block.type === "cardBlock" ?
												<CardBlock key={key} {...block} />
												: block.type === "contactList" ?
													<ContactList key={key} {...block} />
													:
													null
								)
							}
						</div>
						:
						<div className='blogsContainer'>
							{
								Object.entries(websiteData.posts).map(([key, post]) => <BlogPost key={key} {...post} onClick={() => { setShowBlog(post) }} />)
							}
						</div>
				}
				{showSaveContact && <SaveContact />}

				<Footer ClassName="mobileFooter" />
			</div>

		</div >
	)
}
