import React from 'react'
import './Styles/SaveContact.css'

export default function SaveContact() {
	return (
		<div className='saveContactContainer'>
			<div className='saveContact'>
				<div>Save Contact</div>
				<div className='saveContactIcon'/>
			</div>
		</div>
	)
}
