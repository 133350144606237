import React from 'react'
import './Styles/Footer.css'

export default function Footer({ ClassName }) {
	return (
		<div className={ClassName}>
			<div className='footer'>
				<div className="footerText">Made By</div>
				<div className="footerLogo" />
			</div>
		</div>
	)
}
