import React from 'react'
import './Styles/Icon.css'

import InstagramIcon from '../Assets/InstagramIcon.svg'
import TwitterIcon from '../Assets/TwitterIcon.svg'
import TikTokIcon from '../Assets/TikTokIcon.svg'
import LinkIcon from '../Assets/LinkIcon.svg'
import ContactIcon from '../Assets/ContactIcon.svg'
import ArrowIcon from '../Assets/Arrow.svg'
import ThreadIcon from '../Assets/ThreadIcon.svg'
import LinkedinIcon from '../Assets/LinkedinIcon.svg'


export default function Icon({ onClick, type, link, ...props }) {
	const socialIcon = {
		instagram: InstagramIcon,
		twitter: TwitterIcon,
		tikTok: TikTokIcon,
		link: LinkIcon,
		contact: ContactIcon,
		arrow: ArrowIcon,
		thread: ThreadIcon,
		linkedin: LinkedinIcon
	}

	const mode = localStorage.getItem('mode')
	return (
		<div className='icon'
			onClick={() => {
				if (link) {
					window.open(link, '_blank')
				}
				else if (onClick) {
					onClick()
				}
			}}
		>
			<div style={{
				backgroundImage: `url(${socialIcon[type]})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				filter: mode === 'dark' ? 'brightness(100%)' : 'invert(1) brightness(100%)',
			}} />
		</div>
	)
}
