import React from 'react'
import './Styles/TextBlock.css'

export default function TextBlock({text}) {
	return (
		<div className='textBlock'>
			<div className="textBlockText">{text}</div>
		</div>
	)
}
