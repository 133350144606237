import React, { useEffect, useRef, useState } from 'react';
import './Styles/LinkFolder.css';
import Icon from './Icon';
import LinkCard from './LinkCard';

export default function LinkFolder({title, cards, direction}) {
	const containerRef = useRef(null);
	const [showLeftBlur, setShowLeftBlur] = useState(false);
	const [showRightBlur, setShowRightBlur] = useState(true);
	const isRTL = direction === 'rtl';

	useEffect(() => {
		const handleScroll = () => {
			const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
			if(!isRTL){
				setShowLeftBlur(scrollLeft > 0);
				setShowRightBlur(scrollLeft + clientWidth < scrollWidth);
			}
			else{
				setShowLeftBlur(clientWidth - scrollLeft  < scrollWidth); 
				setShowRightBlur(scrollLeft < 0);
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);
		handleScroll(); // Initial check

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	});

	const scrollLeft = () => {
		containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
	};

	const scrollRight = () => {
		containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
	};
	
	return (
		<div className='linkFolder'>
			<div className="linkFolderTitle">
				<Icon type="link" />
				<div>{title}</div>
			</div>
			<div className='BlurContainer'>
				{
					showLeftBlur &&
					<div className='linkFolderBlur leftBlur' >
						<Icon type="arrow" onClick={scrollLeft} />
					</div>
				}
				<div className="linkFolderContainer" ref={containerRef}>
					<div className="linkFolderContainerLinks">
						{
							Object.entries(cards).map(([key, card]) => <LinkCard key={key} {...card} />)
						}
					</div>
				</div>
				{
					showRightBlur &&
					<div className='linkFolderBlur rightBlur'>
						<Icon type="arrow" onClick={scrollRight} />
					</div>
				}
			</div>
		</div>
	);
}