import React from 'react'
import "./Styles/Blog.css"

export default function Blog({ date, title, thumbnail, CloseBlog, body }) {
	console.log(body)
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();

		const daySuffix = (day) => {
			if (day > 3 && day < 21) return 'th';
			switch (day % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		};

		return `${day}${daySuffix(day)} ${month} ${year}`;
	};

	return (
		<div className="blogContainer">
			<div className='blog'>
				<div className='blogThumbnail'
					style={{
						background: `linear-gradient(to bottom, transparent, transparent, white 100%), url(${thumbnail}) no-repeat center`, backgroundSize: "cover",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat"
					}}>
					<div className='blogTop'>
						<div>{formatDate(date)}</div>
						<div className='blogClose' onClick={CloseBlog}>
							<div className='blogCloseIcon' />
						</div>
					</div>
				</div>
				<div className='blogTitle'>{title}</div>
				<div className='blogBody'>{body}</div>
			</div>
		</div>
	)
}