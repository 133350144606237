import './App.css';
import Home from './Pages/Home';

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDPAJznZuXyYQIa6e_5zT8rTNSSAUI9Iwk",
  authDomain: "nuha-372bf.firebaseapp.com",
  projectId: "nuha-372bf",
  storageBucket: "nuha-372bf.appspot.com",
  messagingSenderId: "799992494470",
  appId: "1:799992494470:web:fd073e45ded6335d115407"
};

const app = initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
