import React, { useEffect, useRef, useState } from 'react'
import './Styles/LinkCard.css'

export default function LinkCard({ title, description, thumbnail, link, action }) {
  const [titleLines, setTitleLines] = useState(0);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const titleHeight = titleRef.current.clientHeight;
    const lineHeight = parseFloat(window.getComputedStyle(titleRef.current).lineHeight);
    const lines = Math.round(titleHeight / lineHeight);
    setTitleLines(lines);
  }, []);

  useEffect(() => {
    if (titleLines === 1) {
      descriptionRef.current.style.webkitLineClamp = 3;
    } else {
      descriptionRef.current.style.webkitLineClamp = 2;
    }
  }, [titleLines]);

  return (
    <div className='linkCard' onClick={() => {
      if (link) {
        window.open(link, '_blank')
      }
    }}>
      <div className="linkCardThumbnail"
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      />

      <div className="linkCardInfo">
        <div className="linkCardTitle" ref={titleRef}>{title}</div>
        <div className="linkCardDescription" ref={descriptionRef}>{description}</div>
      </div>

      <div className="linkCardButton">{action}</div>
    </div>
  )
}
