import React, { useState, useEffect, useRef } from 'react';
import './Styles/BlogPost.css';

export default function BlogPost({ title, description, thumbnail, ...props}) {
  const [titleLines, setTitleLines] = useState(0);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const titleHeight = titleRef.current.clientHeight;
    const lineHeight = parseFloat(window.getComputedStyle(titleRef.current).lineHeight);
    const lines = Math.round(titleHeight / lineHeight);
    setTitleLines(lines);
  }, []);

  useEffect(() => {
    if (titleLines === 1) {
      descriptionRef.current.style.webkitLineClamp = 3;
    } else {
      descriptionRef.current.style.webkitLineClamp = 2;
    }
  }, [titleLines]);

  return (
    <div className='blogPost' {...props}>
      <div className="blogPostInfo">
        <div className="blogPostTitle" ref={titleRef}>{title}</div>
        <div className="blogPostDescription" ref={descriptionRef}>{description}</div>
      </div>

      <div className="blogPostThumbnail"
        style={{
          backgroundImage: `url(${thumbnail})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      />
    </div>
  );
}