import React, { useEffect, useRef, useState } from 'react'
import './Styles/CardBlock.css'

export default function CardBlock({title, description, thumbnail, link, action, color, background}) {
	const [titleLines, setTitleLines] = useState(0);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

	useEffect(() => {
    const titleHeight = titleRef.current.clientHeight;
    const lineHeight = parseFloat(window.getComputedStyle(titleRef.current).lineHeight);
    const lines = Math.round(titleHeight / lineHeight);
    setTitleLines(lines);
  }, []);

  useEffect(() => {
    if (titleLines === 1) {
      descriptionRef.current.style.webkitLineClamp = 3;
    } else {
      descriptionRef.current.style.webkitLineClamp = 2;
    }
  }, [titleLines]);

	return (
		<div className='cardBlock' onClick={()=> window.open(link, '_blank')} style={color && background? {color:color, background:background} : color?{color:color}:background?{background:background}: null}>
			<div className="cardBlockThumbnail"
				style={{
					backgroundImage: `url(${thumbnail})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat"
				}}
			/>

			<div className="cardBlockInfo">
				<div className="cardBlockTitle" ref={titleRef}>{title}</div>
				<div className="cardBlockDescription" ref={descriptionRef}>{description}</div>
			</div>

			<div className="cardBlockButton">{action}</div>
		</div>
	)
}